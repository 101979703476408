import { get, isEmpty, omit, set } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, withHandlers, withProps } from "recompose";
import { initialize } from "redux-form";

import {
  UserDataSelectors,
  withAppLoader,
  withLocalServiceState,
} from "@dpdgroupuk/mydpd-app";

import { CREATE_SHIPMENT_FORM, ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { withImportBanner } from "~/features";
import { ShipmentModels } from "~/models";
import withShipmentAsyncValidation from "~/pages/Shipment/hocs/withShipmentAsyncValidation";
import { ReferenceActions } from "~/redux";
import { getValue } from "~/utils/object";
import { initializeForm, touchFields } from "~/utils/reduxForm";

import withCreateShipmentPrint from "../Shipment/hocs/withCreateShipmentPrint";
import withShipmentData from "../Shipment/hocs/withShipmentData";
import Shipment from "../Shipment/Shipment";
import * as CreateShipmentActions from "./actions";
import {
  getCopyShipmentInitialDataValues,
  getCreateShipmentInitialValues,
} from "./models";
import * as CreateShipmentSelectors from "./selectors";
import { ShipmentSelectors } from "~/pages/Shipment/redux";

export default compose(
  withRouter,
  withProps(({ location }) => ({
    shipment: get(location, "state.shipment"),
    pageConfig: {
      formName: CREATE_SHIPMENT_FORM,
      pageTitle: S.CREATE_SHIPMENT,
      pageMiddleText: S.ALL_FIELDS_MARKED_REQUIRED,
    },
  })),
  withLocalServiceState,
  connect(
    (state, { localServiceState, pageConfig }) => ({
      disabledFields: CreateShipmentSelectors.getDisabledFields(state, {
        formName: CREATE_SHIPMENT_FORM,
      }),
      requiredFields: ShipmentSelectors.getShipmentRequiredFields(
        state,
        pageConfig
      ),
      isImportServiceAvailable: localServiceState.isRunning,
      storageDate: UserDataSelectors?.getItem(state.app, "date"),
      allowedFields: CreateShipmentSelectors.getAllowedFields(state, {
        formName: CREATE_SHIPMENT_FORM,
      }),
    }),
    dispatch => ({
      createProduct: (product, packageNumber) =>
        dispatch(
          CreateShipmentActions.createParcelProduct(product, packageNumber)
        ),
      initializeCreateShipmentForm: initialValues => {
        dispatch(initialize(CREATE_SHIPMENT_FORM, initialValues));
        dispatch(touchFields(CREATE_SHIPMENT_FORM, initialValues));
      },
    })
  ),
  withShipmentAsyncValidation,
  withShipmentData,
  connect(
    (state, { pageConfig }) => ({
      shipmentDetailsReview: CreateShipmentSelectors.getShipmentDetailsReview(
        state,
        pageConfig
      ),
    }),
    (
      dispatch,
      {
        pageConfig,
        notifier,
        abortController,
        setAbortController,
        banner,
        overlay,
        inboundServiceErrorBannerId,
        outboundServiceErrorBannerId,
        generateUniqueSenderRef1,
        shippingSettings,
        selectedAddressBook,
        profile,
        preferences,
        uniqueSenderRef1,
        validateOnUniqueShipmentRef1,
        createShipmentValues,
      }
    ) => ({
      handleSubmitAddressbook: dispatch(
        CreateShipmentActions.handleSubmitAddressbook({ pageConfig, notifier })
      ),
      onClickClear: overlay.showWhile(async () => {
        setAbortController(abortController.abort());
        if (shippingSettings.allocateSenders) {
          await generateUniqueSenderRef1();
        }
        dispatch(
          CreateShipmentActions.clearCreateShipmentPage(pageConfig.formName)
        );
        setAbortController(new AbortController());
        banner.hideById(inboundServiceErrorBannerId);
        banner.hideById(outboundServiceErrorBannerId);
      }),
      onOutboundCountryChange: async selection => {
        const countryCode = selection?.value || S.GB;
        const deliveryInstructions = profile?.useMyDpdAccountSettings
          ? getValue(profile, "defaultInformation", "").toUpperCase()
          : "";
        const additionalFields = {};

        if (!selectedAddressBook?.shippingRef1) {
          additionalFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] =
            ShipmentModels.getDefaultUniqueShippingRef1(
              countryCode,
              uniqueSenderRef1,
              {
                preferences,
                shippingSettings,
              }
            );
        }

        dispatch(ReferenceActions.setActiveOutboundService({}));
        dispatch(
          initializeForm(pageConfig.formName, {
            ...(deliveryInstructions && {
              [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
                deliveryInstructions,
            }),
            [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
              ShipmentModels.getDefaultTotalWeight(
                profile,
                preferences,
                countryCode,
                getValue(
                  createShipmentValues,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                  ""
                )
              ),
            [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
              ShipmentModels.getDefaultNumberOfParcels(
                preferences,
                countryCode
              ),
            ...additionalFields,
          })
        );
        // https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_w0IY9V6GAqAADNEM.html
        // run async validation manually since redux form doesn't give new value in time
        // async background task
        additionalFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] &&
          validateOnUniqueShipmentRef1(
            getValue(
              additionalFields,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
            )
          ).catch(() => {
            // ignore error because it's shown in the popup inside validateOnUniqueShipmentRef1
          });
      },
    })
  ),
  withAppLoader({
    loadFn: async ({
      setAbortController,
      fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef,
      fetchAndProcessServices,
      initializeShipment,
      preferences,
      shippingSettings,
      runCheckPostcodeIssues,
      initWithShipmentDate,
      history,
      location,
      initializeCreateShipmentForm,
      shipment: shipmentFromProps,
      storageDate,
      customer,
      customerPrefs,
      securitySettings,
      authUser,
      pageConfig,
    }) => {
      setAbortController(new AbortController());

      if (!isEmpty(shipmentFromProps)) {
        const shipment = omit(shipmentFromProps, [
          ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
          "outboundConsignment.collectionDetails",
        ]);

        const {
          deliveryCountry,
          currencies,
          exportReasons,
          countries,
          uniqueSenderRef1,
          activeProfile,
        } = await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef({
          isAvailableInvoice: !isEmpty(shipment.invoice),
          shouldGenerateUniqueSenderRef1:
            ShipmentModels.shouldGenerateUniqueSenderRef1(
              pageConfig.formName,
              shippingSettings.allocateSenders
            ),
          shipmentCountryCode: getValue(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          ),
          shipmentProfileCode: getValue(
            shipment,
            ShipmentEntity.PROFILE_CODE,
            ""
          ),
        });
        // @see https://geopost.jira.com/browse/CSHIP-4527; https://geopost.jira.com/browse/CSHIP-6772
        // When user tries to copy printed and voided set default values from settings
        // set it before fetchAndProcessServices to fetch proper services
        if (shipment.isVoided) {
          set(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
            ShipmentModels.getDefaultNumberOfParcels(
              preferences,
              getValue(
                shipment,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              )
            )
          );
          set(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
            ShipmentModels.getDefaultTotalWeight(
              activeProfile,
              preferences,
              getValue(
                shipment,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              ),
              getValue(
                shipment,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
              )
            )
          );
        }

        const {
          outboundNetworks,
          inboundNetworks,
          selectedOutboundNetwork,
          outboundErrors,
          inboundErrors,
        } = await fetchAndProcessServices({
          shipment,
          profile: activeProfile,
          selectedCountry: deliveryCountry,
        });

        const initialValues = getCopyShipmentInitialDataValues({
          exportReasons,
          outboundNetworks,
          selectedOutboundNetwork,
          inboundNetworks,
          customer,
          preferences,
          customerPrefs,
          profile: activeProfile,
          shipment,
          securitySettings,
          currencies,
          countries,
          authUser,
          shippingSettings,
          uniqueSenderRef1,
          storageDate,
        });

        await initializeShipment({
          shipment,
          inboundNetworks,
          selectedOutboundNetwork,
          outboundErrors,
          inboundErrors,
          initialValues,
        });

        if (get(location, "state.shipment")) {
          history.replace({
            pathname: location.pathname,
            state: {},
          });
        }
      } else {
        const { countries, uniqueSenderRef1, activeProfile } =
          await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef({
            shouldGenerateUniqueSenderRef1:
              ShipmentModels.shouldGenerateUniqueSenderRef1(
                pageConfig.formName,
                shippingSettings.allocateSenders
              ),
          });

        const createShipmentInitialValues = getCreateShipmentInitialValues(
          activeProfile,
          countries,
          preferences,
          storageDate,
          shippingSettings,
          uniqueSenderRef1
        );
        await initializeCreateShipmentForm(createShipmentInitialValues);
      }
      runCheckPostcodeIssues();
      initWithShipmentDate();
    },
  }),
  withHandlers({
    onBackClick:
      ({ banner, history }) =>
      () => {
        banner.hideAll();
        history.goBack();
      },
  }),
  withImportBanner,
  withCreateShipmentPrint
)(Shipment);
