import { useMemo } from "react";

import { compose } from "recompose";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ReturnDetailsContent from "~/components/ReturnDetailsContent";
import { RETURN_DETAILS } from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const ReturnDetails = props => {
  const { createShipmentSyncErrors } = props;
  const isValidDeliveryDetailsSection = useMemo(
    () => ShipmentModels.isValidReturnDetailsSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      withStepCounter
      totalSteps={props.totalSteps}
      step={props.step}
      complete={!props.pristine && isValidDeliveryDetailsSection}
      help={help[RETURN_DETAILS]}
      title={S.RETURN_DETAILS_TITLE}
      helpModalTitle={S.RETURN_DETAILS_TITLE}
    >
      <ReturnDetailsContent {...props} />
    </Step>
  );
};

ReturnDetails.propTypes = {
  ...propTypes,
};

export default HOC => compose(HOC)(ReturnDetails);
