import { useCallback, useEffect, useMemo } from "react";

import { flow } from "lodash/fp";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { change, Field, propTypes, reduxForm, reset } from "redux-form";

import {
  Button,
  CoverModalButton,
  FormControl,
  Modal,
} from "@dpdgroupuk/mydpd-ui";

import {
  getEditExporterFormValues,
  getExporterModalRequiredFields,
  getExporterSelectedCountry,
  isVisibleTaxRequiredFields,
} from "~/components/EditExporterModal/selectors";
import {
  EDIT_EXPORTER_FORM,
  EXPORTER_DETAILS,
  ExporterDetailsEntity,
  Fields,
  IMPORTER_DETAILS,
  INVOICE,
  ShipmentEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import PostcodeFinderInput from "~/features/PostcodeFinder";
import withEditInvoicePostcodeAutocomplete from "~/hocs/withInvoicePostcodeAutocomplete";
import { AddressModels, Normalizers } from "~/models";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import {
  countryValidation,
  exporterUkimsValidation,
} from "~/models/validators/additionalValidations";
import { exporterDetailsSchema } from "~/models/validators/exporterDetailsSchema";
import { ShipmentActions } from "~/pages/Shipment/redux";
import createValidator from "~/utils/joiReduxForm";
import { getValue } from "~/utils/object";
import { formatMessage, joinStringsWithComma } from "~/utils/string";

import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import styles from "./EditExporterModal.module.scss";
import { UmsSelectors } from "~/redux";

const EditExporterModal = ({
  onFieldEntry,
  onPostcodeSelectionChange,
  open,
  onHide,
  onReset,
  countries,
  handleSubmit,
  requiredFields,
  isVisibleTaxRequiredFields,
  selectedCountry,
  onCountryChange,
  onLoad,
  disabled,
}) => {
  useEffect(() => {
    onLoad();
  }, []);

  const onCancel = useCallback(() => {
    onHide();
    onReset();
  }, [onReset, onHide]);

  const withAutocomplete = useMemo(
    () => AddressModels.isCountryGB(selectedCountry?.countryKey),
    [selectedCountry?.countryKey]
  );

  return (
    <Modal
      id="edit-exporter-modal"
      className={styles.editExporterDialog}
      show={open}
      onHide={onCancel}
    >
      <Modal.Header>
        {S.EDIT_EXPORTER}
        <CoverModalButton
          items={help[EXPORTER_DETAILS]}
          title={S.EDIT_EXPORTER}
        />
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0">
          <Row>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={CountriesAutocomplete}
                    id={ExporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    name={ExporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    label={S.EXPORTER_COUNTRY}
                    helperText
                    onCountryChange={onCountryChange}
                    countries={countries}
                    required={
                      requiredFields[ExporterDetailsEntity.ADDRESS.COUNTRY_CODE]
                    }
                    selectedCountry={selectedCountry}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={PostcodeFinderInput}
                    label={S.POSTAL_ZIP_CODE}
                    name={ExporterDetailsEntity.ADDRESS.POSTCODE}
                    labelKey={option => option.postcode}
                    optionLabelMapper={option =>
                      joinStringsWithComma([
                        option.organisation,
                        option.property,
                        option.street,
                        option.town,
                      ])
                    }
                    onSelectionChange={values => {
                      onPostcodeSelectionChange(values);
                    }}
                    showFindButton={false}
                    maxLength={8}
                    helperText={S.DESTINATION}
                    onBlur={onFieldEntry}
                    withAutocomplete={withAutocomplete}
                    required={
                      requiredFields[ExporterDetailsEntity.ADDRESS.POSTCODE]
                    }
                    id={ExporterDetailsEntity.ADDRESS.POSTCODE}
                    selectedCountry={selectedCountry}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ORGANISATION_OR_NAME}
                    name={ExporterDetailsEntity.ADDRESS.ORGANISATION}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_1}
                    name={ExporterDetailsEntity.ADDRESS.STREET}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ExporterDetailsEntity.ADDRESS.STREET]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_2}
                    name={ExporterDetailsEntity.ADDRESS.LOCALITY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CITY}
                    name={ExporterDetailsEntity.ADDRESS.TOWN}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ExporterDetailsEntity.ADDRESS.TOWN]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.COUNTY_STATE}
                    name={ExporterDetailsEntity.ADDRESS.COUNTY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CONTACT_NAME}
                    name={ExporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CONTACT_TELEPHONE}
                    name={ExporterDetailsEntity.CONTACT_DETAILS.TELEPHONE}
                    helperText={S.MAX_15_CHARACTERS_INCLUDING_SPACES}
                    onBlur={onFieldEntry}
                    normalize={flow(
                      Normalizers.emptyValueNormalize,
                      Normalizers.phoneNumberNormalize
                    )}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.EORI)}
                    name={ExporterDetailsEntity.EORI_NUMBER}
                    onBlur={onFieldEntry}
                    required={requiredFields[ExporterDetailsEntity.EORI_NUMBER]}
                    helperText
                    maxLength={14}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.VAT)}
                    name={ExporterDetailsEntity.VAT_NUMBER}
                    onBlur={onFieldEntry}
                    helperText
                    minLength={4}
                    maxLength={45}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {isVisibleTaxRequiredFields[Fields.UKIMS_NUMBER] && (
                <Row>
                  <Col>
                    <Field
                      component={FormControl.Input}
                      label={formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.UKIMS)}
                      name={ExporterDetailsEntity.UKIMS_NUMBER}
                      onBlur={onFieldEntry}
                      required={
                        requiredFields[ExporterDetailsEntity.UKIMS_NUMBER]
                      }
                      helperText={S.MAX_32_CHARACTERS}
                      maxLength={32}
                    />
                  </Col>
                </Row>
              )}
              {isVisibleTaxRequiredFields[Fields.DESTINATION_TAX_ID_REG_NO] && (
                <Row>
                  <Col>
                    <Field
                      component={FormControl.Input}
                      label={S.DEST_TAX_ID_REG_NUMBER}
                      name={ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO}
                      onBlur={onFieldEntry}
                      maxLength={45}
                      helperText={S.MAX_45_CHARACTERS}
                      required={
                        requiredFields[
                          ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO
                        ]
                      }
                      normalize={Normalizers.emptyValueNormalize}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              )}
              {isVisibleTaxRequiredFields[Fields.GST_VAT_PAID] && (
                <Row>
                  <Col>
                    <Field
                      component={FormControl.Dropdown}
                      label={S.GST_VAT_PAID}
                      placeholder={S.PLEASE_SELECT}
                      name={ExporterDetailsEntity.GST_VAT_PAID}
                      values={S.DEFAULT_BOOLEAN_KEY_VALUE}
                      onBlur={onFieldEntry}
                      required
                      textTransform={S.UPPERCASE}
                      helperText
                      normalize={Normalizers.booleanOrNullValueNormalize}
                      format={Normalizers.booleanOrNullValueFormat}
                    />
                  </Col>
                </Row>
              )}
              {isVisibleTaxRequiredFields[Fields.FDA_NUMBER] && (
                <Row>
                  <Col>
                    <Field
                      component={FormControl.Input}
                      label={S.FDA_NUMBER}
                      name={ExporterDetailsEntity.FDA_NUMBER}
                      onBlur={onFieldEntry}
                      required={
                        requiredFields[ExporterDetailsEntity.FDA_NUMBER]
                      }
                      helperText={S.MAX_30_CHARACTERS}
                      maxLength={30}
                      normalize={Normalizers.emptyValueNormalize}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onCancel}>
          {S.CANCEL}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {S.SAVE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditExporterModal.propTypes = {
  ...propTypes,
  shippingVersion: PropTypes.number,
  open: PropTypes.bool,
  isVisibleTaxRequiredFields: PropTypes.object,
  disabled: PropTypes.bool,
  countries: PropTypes.array,
  requiredFields: PropTypes.object,
  createShipmentValues: PropTypes.object,
  values: PropTypes.object,
  selectedCountry: PropTypes.object,
  onHide: PropTypes.func,
  onReset: PropTypes.func,
  onCountryChange: PropTypes.func,
  onFieldEntry: PropTypes.func,
  onPostcodeSelectionChange: PropTypes.func,
};

export default compose(
  withEditInvoicePostcodeAutocomplete(EDIT_EXPORTER_FORM),
  connect(
    (
      state,
      { editExporterInitialValues, selectedService, createShipmentValues }
    ) => ({
      initialValues: editExporterInitialValues,
      requiredFields: getExporterModalRequiredFields(selectedService)(state),
      selectedCountry: getExporterSelectedCountry(state),
      isVisibleTaxRequiredFields: isVisibleTaxRequiredFields(
        selectedService,
        createShipmentValues
      )(state),
      formValues: getEditExporterFormValues(state),
      customer: UmsSelectors.getCustomer(state),
    }),
    dispatch => ({
      onReset: () => dispatch(reset(EDIT_EXPORTER_FORM)),
      onCountryChange: selection =>
        dispatch(
          ShipmentActions.changeCountry(
            { formName: EDIT_EXPORTER_FORM },
            ExporterDetailsEntity.ADDRESS.COUNTRY_CODE,
            selection.value
          )
        ),
    })
  ),
  withHandlers({
    onSubmit:
      ({ saveInvoiceExporterDetails }) =>
      values =>
        saveInvoiceExporterDetails(values),
    onSubmitSuccess:
      ({ onHide, onReset }) =>
      () => {
        onHide();
        onReset();
      },
  }),
  reduxForm({
    form: EDIT_EXPORTER_FORM,
    shouldError: () => true,
    validate: (values, props) => {
      const additionalValues = {
        ...values,
        [INVOICE]: {
          [IMPORTER_DETAILS]: {
            [Fields.IS_BUSINESS]:
              props.createShipmentValues[
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
              ],
          },
        },
      };
      return createValidator(exporterDetailsSchema(props), [
        () =>
          postcodeValidation(
            props,
            props.selectedCountry,
            ExporterDetailsEntity.ADDRESS.POSTCODE
          ),
        () =>
          countryValidation(props, ExporterDetailsEntity.ADDRESS.COUNTRY_CODE),
        () => exporterUkimsValidation(props),
      ])(additionalValues, props);
    },
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        formValues,
        initialValues,
        isVisibleTaxRequiredFields,
      } = this.props;
      if (
        isVisibleTaxRequiredFields.gstVatPaid !==
        prevProps.isVisibleTaxRequiredFields.gstVatPaid
      ) {
        dispatch(
          change(
            EDIT_EXPORTER_FORM,
            ExporterDetailsEntity.GST_VAT_PAID,
            getValue(
              formValues,
              ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO
            )
              ? initialValues[ExporterDetailsEntity.GST_VAT_PAID]
              : null
          )
        );
      }
    },
  })
)(EditExporterModal);
