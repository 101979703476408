import Joi from "joi";

import { CONTACT_DETAILS, Fields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

import {
  contactName,
  countryCode,
  county,
  destinationTaxId,
  exporterEoriNumber,
  fdaRegistrationNumber,
  gstVatPaid,
  locality,
  organisation,
  postcode,
  street,
  telephone,
  town,
  ukimsNumber,
  vatNumber,
} from "./commonSchema";
import { SettingsModels } from "~/models";

const baseExporterDetails = {
  address: Joi.object({
    organisation,
    street,
    locality,
    town,
    county,
    postcode,
    countryCode: countryCode.label(S.EXPORTER_COUNTRY),
  }),
  [Fields.VAT_NUMBER]: vatNumber.label(
    formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.VAT)
  ),
  [CONTACT_DETAILS]: Joi.object({
    telephone,
    contactName,
  }),
  [Fields.EORI_NUMBER]: exporterEoriNumber,
  [Fields.FDA_NUMBER]: fdaRegistrationNumber,
};

const oldExporterDetails = Joi.object().keys({
  ...baseExporterDetails,
  [Fields.GST_VAT_PAID]: gstVatPaid,
  [Fields.DESTINATION_TAX_ID_REG_NO]: destinationTaxId,
});

const newExporterDetails = Joi.object().keys({
  ...baseExporterDetails,
  [Fields.UKIMS_NUMBER]: ukimsNumber.label(
    formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.UKIMS)
  ),
});

export const exporterDetailsSchema = ({ customer: { shippingVersion } }) =>
  SettingsModels.isNewVersion(shippingVersion)
    ? newExporterDetails
    : oldExporterDetails;
