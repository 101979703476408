import { useMemo } from "react";

import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import PackageDetailsContent from "~/components/PackageDetailsContent";
import { PACKAGE_DETAILS } from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const PackageDetails = props => {
  const { createShipmentSyncErrors, createShipmentSubmitErrors } = props;

  const isValidPackageDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidPackageDetailsSection(
        createShipmentSyncErrors,
        createShipmentSubmitErrors
      ),
    [createShipmentSyncErrors, createShipmentSubmitErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      isLoading={props.isLoadingOutboundServices}
      complete={!props.pristine && isValidPackageDetailsSection}
      title={S.PACKAGE_DETAILS}
      help={help[PACKAGE_DETAILS]}
      helpModalTitle={S.PACKAGE_DETAILS}
    >
      <PackageDetailsContent {...props} />
    </Step>
  );
};

PackageDetails.propTypes = {
  ...propTypes,
};

export default PackageDetails;
