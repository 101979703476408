import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./ActionLink.module.scss";

const Button = ({ children, className, ...props }) => (
  <button {...props} className={className}>
    {children}
  </button>
);

const Link = ({ children, className, ...props }) => (
  <a {...props} className={classNames(styles.underlined, className)}>
    {children}
  </a>
);

const ActionLink = ({ href, text, onClick, icon: Icon, disabled, classes }) => {
  const Wrapper = href ? Link : Button;

  return (
    <Wrapper
      href={href}
      type="button"
      onClick={onClick}
      tabIndex={0}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        styles.actionLink,
        classes.actionLink && classes.actionLink
      )}
      disabled={disabled}
    >
      <span className={styles.text}>{text}</span>
      <Icon />
    </Wrapper>
  );
};

ActionLink.defaultProps = {
  onClick: () => null,
  classes: {},
};

ActionLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
};

Button.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
};

Link.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
};

export default ActionLink;
