import { useCallback, useEffect } from "react";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { change, propTypes, reduxForm, reset } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { Button, withPrompt, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { validateCommodity } from "~/apis/reference";
import {
  EDIT_PRODUCT_FORM,
  Fields,
  PRODUCT_BOOK_SEARCH_FORM,
  ProductEntity,
  SEARCH_CRITERIA_FIELD,
  SEARCH_CRITERIA_VALUE,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withCommodityCodeAutocomplete from "~/hocs/withCommodityCodeAutocomplete";
import withCommodityCodeSearch from "~/hocs/withCommodityCodeSearch";
import {
  countryValidation,
  packageNumberValidation,
  validateCommodityStatic,
} from "~/models/validators/additionalValidations";
import productSchema from "~/models/validators/productSchema";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { ProductBookActions } from "~/redux";
import {
  getCommodityError,
  getEditProductFormValues,
  getEditProductSelectedCountry,
  getFormActiveField,
  getProductBookQuery,
  getProductBookSearchFormValues,
  getProductTotalValue,
  isProductBookSubmitting,
} from "~/redux/productBook/selectors";
import { isIgnoredError } from "~/utils/error";
import createValidator from "~/utils/joiReduxForm";
import { roundToDecimal, separateThousandWithComma } from "~/utils/number";
import { flattenEntityRoutes, getDeepKeys } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import styles from "./PackageContent.module.scss";
import NewEditProduct from "~/components/PackageContent/NewEditProduct";
import OldEditProduct from "~/components/PackageContent/OldEditProduct";

const EditProduct = ({
  createShipmentValues,
  selectedDeliveryCountry,
  selectedProduct,
  onFieldEntry,
  countries,
  isAdditionalCommCodeCheckRequired,
  setIsAsyncCommodityValidated,
  currencyLabel,
  disabledFields,
  isNewVersion,
  requiredFields,
  hiddenFields,
  onCommoditySearch,
  onCommodityCodeSearch,
  onSearch,
  onSelectionChange,
  onCountryChange,
  onClickCancel,
  productBookQuery,
  productTotalValue,
  handleSubmit,
  onClickSaveToProductBook,
  onProductBookSelectionChange,
  change,
  isProductBookSubmitting,
  editProductSelectedCountry,
  onProductBookClear,
}) => {
  useEffect(
    () => setIsAsyncCommodityValidated(!isAdditionalCommCodeCheckRequired),
    [isAdditionalCommCodeCheckRequired]
  );
  const getSearchQuery = useCallback(
    value => ({
      searchString: value,
      deliveryCountryCode: selectedDeliveryCountry.countryKey,
    }),
    [selectedDeliveryCountry]
  );

  const shouldSearchCommodityCode = useCallback(
    () => isAdditionalCommCodeCheckRequired,
    [isAdditionalCommCodeCheckRequired]
  );

  const onCommodityChange = useCallback(() => {
    setIsAsyncCommodityValidated(!isAdditionalCommCodeCheckRequired);
  }, [isAdditionalCommCodeCheckRequired]);

  return (
    <>
      {isNewVersion ? (
        <NewEditProduct
          currencyLabel={currencyLabel}
          isAdditionalCommCodeCheckRequired={isAdditionalCommCodeCheckRequired}
          countries={countries}
          selectedProduct={selectedProduct}
          editProductSelectedCountry={editProductSelectedCountry}
          requiredFields={requiredFields}
          hiddenFields={hiddenFields}
          onFieldEntry={onFieldEntry}
          disabledFields={disabledFields}
          onCommodityChange={onCommodityChange}
          getSearchQuery={getSearchQuery}
          shouldSearchCommodityCode={shouldSearchCommodityCode}
          onCommoditySearch={onCommoditySearch}
          onCommodityCodeSearch={onCommodityCodeSearch}
          onSearch={onSearch}
          onSelectionChange={onSelectionChange}
          onCountryChange={onCountryChange}
          change={change}
          onProductBookClear={onProductBookClear}
          onProductBookSelectionChange={onProductBookSelectionChange}
        />
      ) : (
        <OldEditProduct
          currencyLabel={currencyLabel}
          createShipmentValues={createShipmentValues}
          isAdditionalCommCodeCheckRequired={isAdditionalCommCodeCheckRequired}
          countries={countries}
          selectedProduct={selectedProduct}
          editProductSelectedCountry={editProductSelectedCountry}
          requiredFields={requiredFields}
          hiddenFields={hiddenFields}
          onFieldEntry={onFieldEntry}
          disabledFields={disabledFields}
          onCommodityChange={onCommodityChange}
          getSearchQuery={getSearchQuery}
          shouldSearchCommodityCode={shouldSearchCommodityCode}
          onCommoditySearch={onCommoditySearch}
          onCommodityCodeSearch={onCommodityCodeSearch}
          onSearch={onSearch}
          onSelectionChange={onSelectionChange}
          onCountryChange={onCountryChange}
          change={change}
          onProductBookClear={onProductBookClear}
          onProductBookSelectionChange={onProductBookSelectionChange}
        />
      )}
      <Row className="mt-3 justify-content-end">
        <Col xs="auto" className={styles.text16}>
          {S.TOTAL_PRODUCT_VALUE}
        </Col>
        <Col xs="auto" className={styles.text16}>
          {currencyLabel}
          {separateThousandWithComma(productTotalValue)}
        </Col>
      </Row>
      <Row className={`mt-3 pt-3 ${styles.buttonsWrapper}`}>
        <Col className={`d-flex justify-content-start ${styles.buttonsStart}`}>
          <Button
            disabled={!selectedProduct || disabledFields.packageContent}
            variant="danger"
            onClick={onClickCancel}
          >
            {S.CANCEL}
          </Button>
          <Button
            className="ml-3"
            disabled={
              !productBookQuery ||
              isProductBookSubmitting ||
              disabledFields.packageContent
            }
            variant="primary"
            onClick={onClickSaveToProductBook}
          >
            {S.SAVE_TO_PRODUCT_BOOK}
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            disabled={!selectedProduct || disabledFields.packageContent}
            variant="dark"
            onClick={handleSubmit}
          >
            {S.SAVE_PRODUCT}
          </Button>
        </Col>
      </Row>
    </>
  );
};

EditProduct.propTypes = {
  ...propTypes,
  createShipmentValues: PropTypes.object,
  selectedDeliveryCountry: PropTypes.object,
  selectedProduct: PropTypes.object,
  onFieldEntry: PropTypes.func,
  requiredFields: PropTypes.object,
  disabledFields: PropTypes.object,
  countries: PropTypes.array,
  onSearch: PropTypes.func,
  productTotalValue: PropTypes.number,
  onSelectionChange: PropTypes.func,
  isAdditionalCommCodeCheckRequired: PropTypes.bool,
  isAsyncCommodityValidated: PropTypes.bool,
  setIsAsyncCommodityValidated: PropTypes.func,
  onCancel: PropTypes.func,
  currencyLabel: PropTypes.string,
  onCountryChange: PropTypes.func,
  editProductSelectedCountry: PropTypes.object,
  onClickSaveToProductBook: PropTypes.func,
  hiddenFields: PropTypes.object,
  onClickCancel: PropTypes.func,
  onProductBookSelectionChange: PropTypes.func,
  isProductBookSubmitting: PropTypes.bool,
  isNewVersion: PropTypes.bool,
};

export default compose(
  withPrompt,
  withNotifier,
  withSnackbar,
  withState("isAsyncCommodityValidated", "setIsAsyncCommodityValidated", true),
  connect(
    (state, { pageConfig }) => ({
      editProductFormValues: getEditProductFormValues(state),
      editProductFormActiveField: getFormActiveField(state),
      commodityError: getCommodityError(state),
      productTotalValue: getProductTotalValue(state),
      editProductSelectedCountry: getEditProductSelectedCountry(state),
      productBookQuery: getProductBookQuery(state, pageConfig),
      productBookSearchQuery: getProductBookSearchFormValues(state),
      isProductBookSubmitting: isProductBookSubmitting(state),
    }),
    (dispatch, { onCancel }) => ({
      onCountryChange: selection =>
        dispatch(
          ShipmentActions.changeCountry(
            { formName: EDIT_PRODUCT_FORM },
            ProductEntity.COUNTRY_OF_ORIGIN,
            selection.value
          )
        ),

      updateForm: values => dispatch(initializeForm(EDIT_PRODUCT_FORM, values)),
      updateSearchForm: (field, value) =>
        dispatch(change(PRODUCT_BOOK_SEARCH_FORM, field, value)),
      createOrUpdateProductBook: (productBookId, productBook) =>
        dispatch(
          ProductBookActions.createOrUpdateProductBook(
            productBookId,
            productBook
          )
        ),
      onClickCancel: () => {
        dispatch(reset(EDIT_PRODUCT_FORM));
        onCancel();
      },
      onProductBookClear: () => {
        dispatch(change(EDIT_PRODUCT_FORM, "productBookId"));
      },
      fetchProductBookById: productBookId =>
        dispatch(ProductBookActions.fetchProductBookById(productBookId)),
    })
  ),
  withCommodityCodeAutocomplete,
  withCommodityCodeSearch,
  withHandlers({
    onProductBookSelectionChange: ({
      notifier,
      fetchProductBookById,
      selectedCurrency,
      productBookSearchQuery,
      updateForm,
      updateSearchForm,
      prompt,
    }) =>
      notifier.runAsync(async ({ productBookId }) => {
        const { data } = await fetchProductBookById(productBookId);

        if (data.currency && data.currency !== selectedCurrency) {
          prompt.showInfo({
            header: S.PRODUCT_ERROR_MESSAGE,
            message: S.PRODUCT_CURRENCY_MISMATCH,
          });
        }
        updateForm({
          ...data,
          unitValue: roundToDecimal(data.unitValue),
          unitWeight: roundToDecimal(data.unitWeight),
        });
        updateSearchForm(
          SEARCH_CRITERIA_VALUE,
          data[productBookSearchQuery[SEARCH_CRITERIA_FIELD]]
        );
      }),
    onClickSaveToProductBook: ({
      productBookQuery,
      snackbar,
      notifier,
      createOrUpdateProductBook,
      updateForm,
    }) =>
      notifier.runAsync(
        async () => {
          const { productBookId, ...productBook } = productBookQuery;

          try {
            const { data } = await createOrUpdateProductBook(
              productBookId,
              productBook
            );

            updateForm({
              productBookId: data.productbookId,
              [ProductEntity.CURRENCY]: productBook[ProductEntity.CURRENCY],
            });

            snackbar.showSuccess({
              message: productBookId
                ? S.EDIT_PRODUCT_BOOK
                : S.CREATE_PRODUCT_BOOK,
            });
          } catch (error) {
            if (isIgnoredError(error)) {
              return;
            }

            const errors = error?.errors
              ?.filter(({ message }) => message)
              .map(({ message }) => message)
              .join("\n");

            if (errors) {
              snackbar.showAlert({ message: errors });
            } else {
              throw error;
            }
          }
        },
        { entityName: S.PRODUCT_BOOK }
      ),
    onSubmit:
      ({ createProduct, deleteSelectedProduct, setSelectedProduct }) =>
      ({ productIndex, packageNumber, ...values }) => {
        if (productIndex !== undefined) {
          deleteSelectedProduct();
        }

        createProduct(
          omit(
            omitBy(pick(values, flattenEntityRoutes(ProductEntity)), isNull),
            ProductEntity.SHORT_NAME,
            ProductEntity.CURRENCY
          ),
          packageNumber
        );
        setSelectedProduct();
      },
  }),
  reduxForm({
    form: EDIT_PRODUCT_FORM,
    shouldError: () => true,
    validate: (values, props) =>
      createValidator(productSchema, [
        () => {
          if (!props.hiddenFields[ProductEntity.COUNTRY_OF_ORIGIN]) {
            return countryValidation(
              { ...props, values: props.editProductFormValues },
              ProductEntity.COUNTRY_OF_ORIGIN
            );
          }
        },
        () =>
          packageNumberValidation(
            values?.packageNumber,
            get(
              props.createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
            ) - 1,
            Fields.PACKAGE_NUMBER
          ),
        () =>
          validateCommodityStatic({
            ...props,
            values: props.editProductFormValues,
          }),
      ])(values, props),
    asyncValidate: async (
      { commodityCode },
      _,
      {
        prompt,
        isAdditionalCommCodeCheckRequired,
        selectedDeliveryCountry,
        setIsAsyncCommodityValidated,
        snackbar,
        abortController,
      }
    ) => {
      if (isAdditionalCommCodeCheckRequired && !isEmpty(commodityCode)) {
        try {
          await validateCommodity(
            {
              commodityCode,
              deliveryCountryCode: selectedDeliveryCountry.countryKey,
            },
            {
              signal: abortController.signal,
            }
          );
          setIsAsyncCommodityValidated(true);
        } catch (error) {
          const errorCode = error?.errors?.[0]?.code;
          const errorMessage = error?.errors?.[0]?.message;
          const fieldPath = error?.errors?.[0]?.fieldPath;

          if (errorMessage) {
            const message =
              fieldPath === "deliveryCountryCode" || errorCode === 100
                ? errorMessage
                : S.COMMODITY_CODE_ERROR_MESSAGE(errorMessage);

            prompt.showInfo({
              header: S.COMMODITY_CODE_ERROR,
              message,
            });

            // eslint-disable-next-line no-throw-literal
            throw {
              commodityCode: errorMessage,
            };
          } else if (!isIgnoredError(error)) {
            snackbar.showAlert({
              message: S.FAILED_TO_VALIDATE_COMMODITY,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
            throw error;
          }
        }
      }

      return Promise.resolve();
    },
    asyncBlurFields: [ProductEntity.COMMODITY_CODE],
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { selectedProduct, updateForm } = this.props;

      if (
        !isEqual(prevProps.selectedProduct, selectedProduct) &&
        !isEmpty(selectedProduct)
      ) {
        updateForm(selectedProduct);
      }

      if (prevProps.selectedProduct && !this.props.selectedProduct) {
        this.props.dispatch(reset(PRODUCT_BOOK_SEARCH_FORM));
      }
    },
  })
)(EditProduct);
