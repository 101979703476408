import { without } from "lodash/array";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { autofill, clearFields, getFormValues, stopSubmit } from "redux-form";

import {
  INBOUND_CONSIGNMENT,
  inboundErrorFieldPath,
  InboundServicesRelatedFields,
  OUTBOUND_CONSIGNMENT,
  outboundErrorFieldPath,
  OutboundServicesRelatedFields,
  ShipmentEntity,
} from "~/constants/forms";
import { INBOUND_SERVICES, OUTBOUND_SERVICES } from "~/constants/strings";
import withDefaultReturnDetails from "~/hocs/withDefaultReturnDetails";
import { ServiceModels, ShipmentModels } from "~/models";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import { ReferenceActions } from "~/redux";
import { flatPathToObject, getValue } from "~/utils/object";

export default compose(
  withState("outboundQuery", "setOutboundQuery", {}),
  withState("inboundQuery", "setInboundQuery", {}),
  connect(
    (state, { pageConfig }) => ({
      currentValues: getFormValues(pageConfig.formName)(state),
      newOutboundQuery: ShipmentSelectors.getServiceQuery(OUTBOUND_CONSIGNMENT)(
        state,
        pageConfig
      ),
      newInboundQuery: ShipmentSelectors.getServiceQuery(INBOUND_CONSIGNMENT)(
        state,
        pageConfig
      ),
      inboundMapErrors: error =>
        ShipmentModels.mapBoundQueryErrorsToReduxForm(
          error,
          inboundErrorFieldPath
        ),
      outboundMapErrors: error =>
        ShipmentModels.mapBoundQueryErrorsToReduxForm(
          error,
          outboundErrorFieldPath
        ),
      inboundEntityName: INBOUND_SERVICES,
      outboundEntityName: OUTBOUND_SERVICES,
    }),
    (dispatch, { currentValues, pageConfig, abortController }) => ({
      fetchOutboundServices: query =>
        dispatch(
          ReferenceActions.fetchOutboundServices(query, {
            signal: abortController.signal,
          })
        ),
      fetchInboundServices: query =>
        dispatch(
          ReferenceActions.fetchInboundServices(query, {
            signal: abortController.signal,
          })
        ),
      touchShipmentForm: mappedErrors =>
        dispatch(
          ShipmentActions.touchShipmentFormFields(
            pageConfig.formName,
            Object.keys(mappedErrors),
            currentValues
          )
        ),
      stopSubmitShipmentForm: mappedErrors =>
        dispatch(
          stopSubmit(pageConfig.formName, flatPathToObject(mappedErrors))
        ),
    })
  ),
  withHandlers({
    fetchServicesHandler:
      ({
        stopSubmitShipmentForm,
        touchShipmentForm,
        notifier,
        dispatch,
        pageConfig,
      }) =>
      async (newQuery, fetchServices, mapErrors, entityName) => {
        let services;
        try {
          services = await fetchServices(newQuery);
        } catch (error) {
          const mappedErrors = mapErrors(error);
          if (!isEmpty(mappedErrors)) {
            touchShipmentForm(mappedErrors);
            stopSubmitShipmentForm(mappedErrors);
            notifier.scrollToError(mappedErrors);
          } else {
            entityName === INBOUND_SERVICES &&
              dispatch(
                clearFields(
                  pageConfig.formName,
                  false,
                  false,
                  ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE
                )
              );
            throw error;
          }
        }

        return services;
      },
    populateDefaultOutboundNetwork:
      ({
        authUser,
        profile = {},
        dispatch,
        form,
        selectedCountry,
        selectedService,
        preferences,
        createShipmentValues,
      }) =>
      (services, setupDefaultServiceFromList = true) => {
        const currentService = ServiceModels.getDefaultOutboundService({
          services,
          authUser,
          preferences,
          profile,
          createShipmentValues,
          selectedCountry,
          selectedService,
          setupDefaultServiceFromList,
        });

        dispatch(ReferenceActions.setActiveOutboundService(currentService));
        dispatch(
          autofill(
            form,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
            currentService?.networkKey
          )
        );

        return currentService;
      },
    populateDefaultInboundNetwork:
      ({
        dispatch,
        form,
        profile,
        selectedCountry,
        createShipmentValues,
        authUser,
      }) =>
      services => {
        const service = ServiceModels.getDefaultInboundService({
          profile,
          services,
          selectedCountry,
          createShipmentValues,
          authUser,
        });

        dispatch(ReferenceActions.setActiveInboundService(service));
        dispatch(
          autofill(
            form,
            ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE,
            service?.networkKey
          )
        );
      },
  }),
  withHandlers({
    fetchOutboundServicesHandler: props =>
      props.notifier.runAsync(
        props.showInvisibleFieldError(
          async (setupDefaultServiceFromList, findByServiceKey) => {
            props.stopSubmitShipmentForm({});

            const services = await props.fetchServicesHandler(
              props.newOutboundQuery,
              props.fetchOutboundServices,
              props.outboundMapErrors,
              props.outboundEntityName
            );
            let selectedService = ServiceModels.findPreviousSelectedNetwork({
              services,
              networkKey: getValue(
                props.createShipmentValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                ""
              ),
              serviceKey: getValue(
                props.selectedService,
                "service.serviceKey",
                ""
              ),
              findByServiceKey,
              csvNetworkCode: props.csvService?.networkCode,
            });

            const userServices = ServiceModels.getServicesForUser(
              props.authUser.user,
              props.profile,
              services?.data,
              props.customer
            );

            if (
              (!isEqual(services?.data, props.outboundServices) &&
                !isEqual(selectedService, props.selectedService)) ||
              !selectedService
            ) {
              if (!selectedService) {
                selectedService = props.populateDefaultOutboundNetwork(
                  userServices || [],
                  setupDefaultServiceFromList
                );
              } else {
                props.dispatch(
                  ReferenceActions.setActiveOutboundService(selectedService)
                );
                props.dispatch(
                  autofill(
                    props.form,
                    ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                    selectedService.networkKey
                  )
                );
              }

              // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
              // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
              props.handleLiability(selectedService, props.selectedService);
              props.handleNetworkChange(selectedService, true);
              props.handleTaxIdAndGst(selectedService, props.selectedService);
              props.handleFdaNumber(selectedService, props.selectedService);
              props.handleOutboundTotalWeight(selectedService);
            } else {
              props.dispatch(
                ReferenceActions.setActiveOutboundService(selectedService)
              );
            }
          },
          { uiFields: props.uiFields }
        ),
        { entityName: props.outboundEntityName }
      ),
    fetchInboundServicesHandler: props =>
      props.notifier.runAsync(
        props.showInvisibleFieldError(
          async () => {
            const services = await props.fetchServicesHandler(
              props.newInboundQuery,
              props.fetchInboundServices,
              props.inboundMapErrors,
              props.inboundEntityName
            );
            const selectedService = services?.data?.find(
              service =>
                service.networkKey === props.selectedInboundService?.networkKey
            );
            const userServices = ServiceModels.getServicesForUser(
              props.authUser.user,
              props.profile,
              services?.data,
              props.customer
            );

            if (
              !isEqual(services?.data, props.inboundServices) &&
              !selectedService
            ) {
              props.populateDefaultInboundNetwork(userServices || []);
            }
          },
          { uiFields: props.uiFields }
        ),
        { entityName: props.inboundEntityName }
      ),
    clearServicesQuery:
      ({ setOutboundQuery, setInboundQuery }) =>
      () => {
        setOutboundQuery({});
        setInboundQuery({});
      },
    isValidOutboundConsignment: () => errors =>
      ShipmentModels.isEmptyConsignmentErrors(
        errors,
        without(
          OutboundServicesRelatedFields,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET
        )
      ),
    isValidInboundConsignment: () => errors =>
      ShipmentModels.isEmptyConsignmentErrors(
        errors,
        without(
          InboundServicesRelatedFields,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY,
          ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
          ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY
        )
      ),
  }),
  withDefaultReturnDetails,
  withHandlers({
    onBlurServiceRelatedField:
      ({
        fetchOutboundServicesHandler,
        fetchInboundServicesHandler,
        isValidOutboundConsignment,
        isValidInboundConsignment,
        newOutboundQuery,
        newInboundQuery,
        outboundQuery,
        inboundQuery,
        setOutboundQuery,
        setInboundQuery,
        defaultReturnAddressBookHandler,
        pageFormState,
        customer,
      }) =>
      async (currentValues, prevValues, props) => {
        // NOTE: must save query before fetchOutboundServicesHandler,
        // because handleOutboundTotalWeight from fetchOutboundServicesHandler will trigger it again
        setOutboundQuery(newOutboundQuery);

        // NOTE: Needed for Scanning page to prevent fetching services on initial data
        const initialOutboundQuery = ShipmentModels.getQueryForServices(
          pageFormState?.initial,
          OutboundServicesRelatedFields,
          JSON.parse(newOutboundQuery.collectionDetails),
          pageFormState?.initial?.profileCode,
          OUTBOUND_CONSIGNMENT,
          customer
        );
        const prevActiveField = get(prevValues, "activeField")?.toUpperCase();

        /**
             check if no errors in outbound consignment, compare outbound queries
             fetch new outbound services in case of valid condition
             **/
        if (
          isValidOutboundConsignment(props.syncErrors) &&
          !isEqual(outboundQuery, newOutboundQuery) &&
          !isEqual(newOutboundQuery, initialOutboundQuery)
        ) {
          const setupDefaultServiceFromList = ![
            ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT.toUpperCase(),
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS.toUpperCase(),
          ].includes(prevActiveField);
          const findByServiceKey =
            prevActiveField === ShipmentEntity.SHIPMENT_TYPE.toUpperCase();

          const bannerId = await fetchOutboundServicesHandler(
            setupDefaultServiceFromList,
            findByServiceKey
          );

          if (props.outboundServiceErrorBannerId) {
            props.banner.hideById(props.outboundServiceErrorBannerId);
          }

          props.setOutboundServiceErrorBannerId(bannerId);
        }

        /**
             fetch default return addressbook for inbound consignment
             **/
        if (
          ShipmentModels.isNoneShipmentType(prevValues.shipmentType) &&
          ShipmentModels.isSwapItOrReverseItShipmentType(
            currentValues.shipmentType
          )
        ) {
          await defaultReturnAddressBookHandler();
        }

        /**
             check if no errors in inbound consignment, compare inbound queries
             fetch new inbound services in case of valid condition
             **/
        if (
          isValidInboundConsignment(props.syncErrors) &&
          !isEqual(inboundQuery, newInboundQuery) &&
          ShipmentModels.isSwapItOrReverseItShipmentType(
            currentValues.shipmentType
          )
        ) {
          const inboundBannerId = await fetchInboundServicesHandler();

          if (props.inboundServiceErrorBannerId) {
            props.banner.hideById(props.inboundServiceErrorBannerId);
          }

          props.setInboundServiceErrorBannerId(inboundBannerId);
        }

        /**
             set new inbound query
             **/

        setInboundQuery(newInboundQuery);
      },
  })
);
