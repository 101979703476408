import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import { AddressModels, SettingsModels, ShipmentModels } from "~/models";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { ShipmentEntity } from "~/constants/forms";
import { ReferenceActions } from "~/redux";

export default compose(
  connect(null, (dispatch, { pageConfig }) => ({
    clearContactDetails: () =>
      dispatch(ShipmentActions.clearContactDetails(pageConfig.formName)),
    clearPage: () => dispatch(ShipmentActions.unmountShipmentPage()),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { dispatch, pageConfig, selectedCountry, allowedFields, customer } =
        this.props;
      // POTENTIALLY CAN BE MOVED TO ADDRESS BOOK SELECTION LOGIC
      if (
        ShipmentModels.isSwapItShipmentType(
          prevProps.createShipmentValues?.shipmentType
        ) &&
        !AddressModels.isCountryDomestic(selectedCountry?.countryKey) &&
        !isEmpty(this.props.createShipmentValues?.inboundConsignment)
      ) {
        dispatch(ShipmentActions.resetInboundConsignment(pageConfig.formName));
      }

      if (
        SettingsModels.isNewVersion(customer.shippingVersion) &&
        !allowedFields[ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS] &&
        prevProps.allowedFields[
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
        ]
      ) {
        dispatch(ReferenceActions.clearNiShipmentRequiredFields());
      }
    },
    componentWillUnmount() {
      const {
        clearPage,
        banner,
        consignmentBannerId,
        setConsignmentBannerId,
        abortController,
      } = this.props;

      clearPage();
      banner.hideByType(BANNERS_TYPES.WARNING);
      banner.hideByType(BANNERS_TYPES.ALERT);
      banner.hideByType(BANNERS_TYPES.SUCCESS);

      if (consignmentBannerId) {
        banner.hideById(this.props.consignmentBannerId);
        setConsignmentBannerId();
      }

      abortController.abort();
    },
  })
);
