import { useMemo } from "react";

import PropTypes from "prop-types";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import DeliveryContactContent from "~/components/DeliveryContactContent";
import { DELIVERY_CONTACT } from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const DeliveryContact = props => {
  const { createShipmentSyncErrors } = props;
  const isValidDeliveryContactSection = useMemo(
    () =>
      ShipmentModels.isValidDeliveryContactSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidDeliveryContactSection}
      help={help[DELIVERY_CONTACT]}
      title={S.DELIVERY_CONTACT_TITLE}
      helpModalTitle={S.DELIVERY_CONTACT_TITLE}
    >
      <DeliveryContactContent {...props} />
    </Step>
  );
};

DeliveryContact.propTypes = {
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  ...propTypes,
};

export default DeliveryContact;
