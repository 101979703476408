import { useMemo } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { Card, Legend, Main } from "@dpdgroupuk/mydpd-ui";

import ShipmentActions from "~/components/ShipmentActions";
import { ShipmentEntity } from "~/constants/forms";
import { PLEASE_COMPLETE_ALL_REQUIRED_FIELDS } from "~/constants/strings";

import styles from "./Shipment.module.scss";
import { getSections } from "./view";
import { SettingsModels } from "~/models";

const Shipment = props => {
  const sections = useMemo(
    () => getSections(props),
    [
      props.generateCustomsData,
      props.isSwapItOrReverseItShipmentType,
      props.selectedService?.notifyRequired,
      props.allowedFields[ShipmentEntity.SHIPMENT_TYPE],
      props.allowedFields.productDetailsData,
      props.allowedFields.invoiceDetailsSection,
      props.allowedFields.exporterImporterDetails,
      props.customer.shippingVersion,
    ]
  );

  const isNewVersion = SettingsModels.isNewVersion(
    props.customer.shippingVersion
  );

  return (
    <>
      <Main.Body>
        <Legend
          leftMessage={PLEASE_COMPLETE_ALL_REQUIRED_FIELDS}
          rightMessage={get(props, "authUser.user.username")}
          classes={{ container: "p-0" }}
        />
        <form autoComplete="off">
          <Card.Stack fluid>
            {sections.data.map(
              ({
                section: Section,
                columns = isNewVersion ? 12 : 6,
                order,
                padding,
              }) => (
                <Col
                  key={order}
                  xs={{ order, span: 12 }}
                  md={columns}
                  className={`${styles.col} ${padding}`}
                >
                  {Array.isArray(Section) ? (
                    Section.map(
                      ({ section: SplitSection, order: splitOrder }) => (
                        <SplitSection
                          key={splitOrder}
                          {...props}
                          step={splitOrder}
                          totalSteps={sections.totalSteps}
                        />
                      )
                    )
                  ) : (
                    <Section
                      {...props}
                      step={order}
                      totalSteps={sections.totalSteps}
                    />
                  )}
                </Col>
              )
            )}
          </Card.Stack>
        </form>
      </Main.Body>
      <Main.Footer className="dark">
        <ShipmentActions {...props} />
      </Main.Footer>
    </>
  );
};

Shipment.propTypes = {
  authUser: PropTypes.object,
  isSwapItOrReverseItShipmentType: PropTypes.bool,
  generateCustomsData: PropTypes.bool,
  selectedService: PropTypes.object,
  pageConfig: PropTypes.object,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  isHiddenProfile: PropTypes.bool,
  customer: PropTypes.object,
};

Shipment.defaultProps = {
  allowedFields: {},
};

export default Shipment;
