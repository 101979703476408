import { useMemo } from "react";

import classNames from "classnames";
import get from "lodash/get";
import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ActionLink from "~/components/ActionLink";
import { AddAddressBook } from "~/components/Icon";
import CustomsDataCard from "~/components/ReviewCard/cards/CustomsData";
import DetailsCard from "~/components/ReviewCard/cards/DetailsCard";
import { ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ServiceModels, SettingsModels, ShipmentModels } from "~/models";

import styles from "./ShipmentReview.module.scss";

const ShipmentReview = props => {
  const {
    valid,
    pristine,
    addressAction,
    generateCustomsData,
    isSwapItOrReverseItShipmentType,
    selectedService,
    handleSubmitAddressbook,
    createOrUpdateAddressbook,
    createShipmentValues,
    allowedFields,
    exporterInvoiceDataCard,
    importerInvoiceDataCard,
    returnDetailsReview,
    deliveryDetailsReview,
    shipmentDetailsReview,
    returnPackageReview,
    preferences,
    customer,
  } = props;

  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );

  const generateCustomsDataMessage = useMemo(
    () =>
      ShipmentModels.getGenerateCustomsDataMessage(
        selectedService,
        generateCustomsData,
        preferences
      ),
    [selectedService, generateCustomsData, preferences]
  );
  const directShipmentMessage = useMemo(
    () =>
      ShipmentModels.getDirectShipmentMessage(
        selectedService,
        get(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        )
      ),
    [selectedService, createShipmentValues]
  );

  const column = useMemo(
    () =>
      generateCustomsData ||
      isSwapItOrReverseItShipmentType ||
      ServiceModels.isFlagRequired(selectedService, "prodRequired") ||
      isNewVersion
        ? 6
        : 12,
    [
      generateCustomsData,
      isSwapItOrReverseItShipmentType,
      selectedService,
      isNewVersion,
    ]
  );

  return (
    <Step
      withStepCounter
      totalSteps={props.totalSteps}
      step={props.step}
      title={S.SHIPMENT_REVIEW_TITLE}
      complete={!pristine && valid}
    >
      <div className={classNames(styles.shipmentReview)}>
        <Row>
          <Col xs={12} md={column} className={styles.col}>
            <DetailsCard
              title={S.SHIPMENT_DETAILS}
              data={shipmentDetailsReview}
              className="m-2"
            />
            {allowedFields.exporterImporterDetails && (
              <DetailsCard
                className="m-2"
                title={S.EXPORTER_INVOICE_DETAILS}
                data={exporterInvoiceDataCard}
              />
            )}
            {allowedFields.returnCards && (
              <DetailsCard
                title={S.RETURN_PACKAGE}
                data={returnPackageReview}
                className="m-2"
              />
            )}
            {allowedFields.customsDataCard && (
              <CustomsDataCard
                className="m-2"
                message={[generateCustomsDataMessage, directShipmentMessage]
                  .filter(v => v)
                  .join("\n\n")}
              />
            )}
          </Col>
          <Col xs={12} md={column} className={styles.col}>
            <DetailsCard
              className="m-2"
              action={
                addressAction && (
                  <ActionLink
                    {...addressAction}
                    icon={AddAddressBook}
                    onClick={() =>
                      handleSubmitAddressbook(() =>
                        createOrUpdateAddressbook(addressAction.addressBook)
                      )
                    }
                  />
                )
              }
              title={S.DELIVERY_DETAILS_TITLE}
              data={deliveryDetailsReview}
            />
            {allowedFields.exporterImporterDetails && (
              <DetailsCard
                className="m-2"
                title={S.IMPORTER_INVOICE_DETAILS}
                data={importerInvoiceDataCard}
              />
            )}
            {allowedFields.returnCards && (
              <DetailsCard
                className="m-2"
                title={S.RETURN_DETAILS}
                data={returnDetailsReview}
              />
            )}
          </Col>
        </Row>
      </div>
    </Step>
  );
};

ShipmentReview.propTypes = {
  allowedFields: PropTypes.object,
  exporterInvoiceDataCard: PropTypes.array,
  importerInvoiceDataCard: PropTypes.array,
  returnDetailsReview: PropTypes.array,
  deliveryDetailsReview: PropTypes.array,
  shipmentDetailsReview: PropTypes.array,
  returnPackageReview: PropTypes.array,
  ...propTypes,
};

export default ShipmentReview;
