import { useCallback } from "react";

import Joi from "joi";
import { get } from "lodash";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Field, propTypes, reduxForm, reset, stopSubmit } from "redux-form";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";
import { FormControl, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import Autocomplete from "~/components/Autocomplete";
import {
  FilterFields,
  ProductEntity,
  SEARCH_CRITERIA_VALUE,
  SearchProductOptionsList,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import {
  PRODUCT_BOOK,
  PRODUCT_BOOKS,
  SEARCH,
  UPPERCASE,
} from "~/constants/strings";
import searchProduct from "~/models/validators/searchProduct";
import { ProductBookActions } from "~/redux";
import {
  getErrorMessage,
  isIgnoredError,
  searchCriteriaFields,
} from "~/utils/error";
import createValidator from "~/utils/joiReduxForm";

import { getSearchProductDetails } from "./selectors";

const getHelperText = field =>
  field &&
  `${SEARCH} ${SearchProductOptionsList.find(
    ({ value }) => value === field
  ).label.toLowerCase()}`;

const SearchProduct = ({
  onFieldEntry,
  formValues,
  onChangeProductBookFilter,
  onSearch,
  onSelectionChange,
  onChange,
  disabled,
}) => {
  const searchCriteriaField = get(
    formValues,
    FilterFields.SEARCH_CRITERIA_FIELD
  );

  const getSearchQuery = useCallback(
    value => ({
      searchCriteriaField,
      searchCriteriaValue: value,
    }),
    [searchCriteriaField]
  );

  const formatBeforeSearch = useCallback(value => value.trim());

  const onSelectionChangeHandler = useCallback(
    values =>
      onSelectionChange({
        ...values,
        searchCriteria: searchCriteriaField,
      }),
    [searchCriteriaField, onSelectionChange]
  );

  const shouldSearch = useCallback(
    value => {
      const error = Joi.validate(
        { searchCriteriaField, searchCriteriaValue: value },
        searchProduct
      ).error;

      return !error;
    },
    [searchCriteriaField]
  );

  const labelKey = useCallback(
    option => formValues.searchCriteriaValue || option.searchData,
    [formValues.searchCriteriaValue]
  );

  return (
    <Row>
      <Col lg={5}>
        <Field
          component={FormControl.Dropdown}
          label={PRODUCT_BOOK}
          name={FilterFields.SEARCH_CRITERIA_FIELD}
          values={SearchProductOptionsList}
          onChange={onChangeProductBookFilter}
          onBlur={onFieldEntry}
          helperText
          textTransform={UPPERCASE}
          disabled={disabled}
        />
      </Col>
      <Col lg={7}>
        <Field
          component={Autocomplete}
          label={SEARCH}
          name={FilterFields.SEARCH_CRITERIA_VALUE}
          onBlur={onFieldEntry}
          helperText={getHelperText(formValues.searchCriteriaField)}
          onSearch={onSearch}
          labelKey={labelKey}
          optionLabelMapper={option => option.searchData}
          onSelectionChange={onSelectionChangeHandler}
          withAutocomplete
          maxLength={45}
          type="search"
          minLength={1}
          formatBeforeSearch={formatBeforeSearch}
          getSearchQuery={getSearchQuery}
          shouldSearch={shouldSearch}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

SearchProduct.propTypes = {
  ...propTypes,
  onFieldEntry: PropTypes.func,
  onChangeProductBookFilter: PropTypes.func,
  onSearch: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onChange: PropTypes.func,
};

export default compose(
  withSnackbar,
  connect(
    (state, { form }) => ({
      initialValues: { searchCriteriaField: ProductEntity.SHORT_NAME },
      formValues: getSearchProductDetails(form)(state),
    }),
    (dispatch, { snackbar, form, onClear }) => ({
      onChangeProductBookFilter: () => dispatch(stopSubmit(form), {}),
      onSearch: createCancelableRequest(async (query, options) => {
        try {
          return await dispatch(
            ProductBookActions.fetchProductBooks(query, options)
          );
        } catch (err) {
          if (!isIgnoredError(err)) {
            if (searchCriteriaFields.includes(err?.errors?.[0]?.fieldName)) {
              dispatch(
                stopSubmit(form, {
                  [SEARCH_CRITERIA_VALUE]: err?.errors?.[0]?.message,
                })
              );
            } else {
              snackbar.showAlert({
                message: getErrorMessage(err, PRODUCT_BOOKS),
                displayTime: SHOW_ALERT_DISPLAY_TIME,
              });
            }
          }
        }
      }),
      onChange: value => {
        if (isEmpty(value)) {
          onClear();
        }
      },
    })
  ),
  reduxForm({
    destroyOnUnmount: false,
    validate: createValidator(searchProduct),
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch(reset(this.props.form));
    },
  })
)(SearchProduct);
