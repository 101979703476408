import classNames from "classnames";
import PropTypes from "prop-types";

import { Trash } from "~/components/Icon";
import styles from "./ProductDescriptionListItem.module.scss";

const ProductDescriptionsListItem = props => {
  const { sequenceNumber, text, onRemove, isRemoveBtnVisible } = props;

  return (
    <li className={classNames(styles.item, "mb-3")}>
      <div className={styles.leftContainer}>
        <p className={styles.sequenceNumber}>{sequenceNumber}</p>
        <div className={styles.textContainer}>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
      {isRemoveBtnVisible && (
        <button className={styles.removeButton} onClick={onRemove}>
          <Trash />
        </button>
      )}
    </li>
  );
};

ProductDescriptionsListItem.propTypes = {
  sequenceNumber: PropTypes.number,
  text: PropTypes.string,
  onRemove: PropTypes.func,
  isRemoveBtnVisible: PropTypes.bool,
};

export default ProductDescriptionsListItem;
