import { Col, Row } from "react-bootstrap";

import { compose } from "recompose";
import { propTypes } from "redux-form";
import { useMemo } from "react";

import { Step, Card } from "@dpdgroupuk/mydpd-ui";

import {
  RETURN_DETAILS,
  RETURN_CONTACT,
  PARCEL_RETURN_DETAILS,
  PARCEL_RETURN_SERVICE_DETAILS,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import ReviewCard from "~/components/ReviewCard";
import ReturnDetailsContent from "~/components/ReturnDetailsContent";
import ReturnContactContent from "~/components/ReturnContactContent";
import ReturnPackageDetailsContent from "~/components/ReturnPackageDetailsContent";
import ReturnPackageServiceDetailsContent from "~/components/ReturnPackageServiceDetailsContent";
import { ShipmentModels } from "~/models";

const ReturnShipmentDetails = props => {
  const { createShipmentSyncErrors } = props;
  const isValidDeliveryDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidReturnShipmentDetailsSection(
        createShipmentSyncErrors
      ),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      withStepCounter
      totalSteps={props.totalSteps}
      step={props.step}
      complete={!props.pristine && isValidDeliveryDetailsSection}
      title={S.RETURN_DETAILS_TITLE}
    >
      <Row>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.RETURN_ADDRESS_DETAILS}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[RETURN_DETAILS]}
                helpModalTitle={S.RETURN_ADDRESS_DETAILS}
              />
            }
          >
            <ReturnDetailsContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.RETURN_CONTACT_DETAILS}
            classes={{ card: "h-100" }}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[RETURN_CONTACT]}
                helpModalTitle={S.RETURN_CONTACT_DETAILS}
              />
            }
          >
            <ReturnContactContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.RETURN_PARCEL_DETAILS}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[PARCEL_RETURN_DETAILS]}
                helpModalTitle={S.RETURN_PARCEL_DETAILS}
              />
            }
          >
            <ReturnPackageDetailsContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.RETURN_PACKAGE_SERVICE_DETAILS}
            classes={{ card: "h-100" }}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[PARCEL_RETURN_SERVICE_DETAILS]}
                helpModalTitle={S.RETURN_PACKAGE_SERVICE_DETAILS}
              />
            }
          >
            <ReturnPackageServiceDetailsContent {...props} />
          </ReviewCard>
        </Col>
      </Row>
    </Step>
  );
};

ReturnShipmentDetails.propTypes = {
  ...propTypes,
};

export default HOC => compose(HOC)(ReturnShipmentDetails);
