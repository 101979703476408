import { get, isEmpty, set } from "lodash";

import { Fields, ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import {
  AddressModels,
  ParcelModels,
  ServiceModels,
  SettingsModels,
} from "~/models";
import { roundToDecimal } from "~/utils/number";
import { getValue } from "~/utils/object";

export const getDefaultCurrency = (preferences, profile) =>
  get(profile, "invDefaultCurrency") ||
  get(preferences, "shippingDefaults.international.intCurrency") ||
  S.GBP;

const getOldVersionCustomsFieldsConfig = (
  service,
  country,
  generateCustomsData,
  customer
) => {
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
  const deliveryDescription = isNewVersion
    ? ServiceModels.isFlagRequired(service, "contentsRequired")
    : true;
  const newDeliveryDescriptionHidden =
    isNewVersion && ServiceModels.isFlagNeedles(service, "contentsRequired");

  if (isEmpty(service)) return {};

  if (ServiceModels.isFlagRequired(service, "proforma")) {
    if (ServiceModels.isFlagDescription(service, "prodRequired")) {
      return {
        deliveryDescription,
        deliveryDescriptionHidden: newDeliveryDescriptionHidden,
      };
    }

    return {
      deliveryDescription,
      customsValueDisabled: true,
      deliveryDescriptionHidden: newDeliveryDescriptionHidden,
    };
  }

  if (ServiceModels.isFlagOptional(service, "proforma")) {
    if (generateCustomsData) {
      return {
        deliveryDescription,
        deliveryDescriptionHidden: newDeliveryDescriptionHidden,
        customsValueDisabled: true,
      };
    }

    return {
      customsValue: true,
      deliveryDescription,
      deliveryDescriptionHidden: newDeliveryDescriptionHidden,
    };
  }

  if (AddressModels.isCountryGB(country.countryKey)) {
    if (
      ServiceModels.isFlagRequired(service, "prodRequired") ||
      ServiceModels.isFlagOptional(service, "prodRequired")
    ) {
      return { customsValueDisabled: true, deliveryDescriptionHidden: true };
    }

    return {};
  }

  if (ServiceModels.isFlagRequired(service, "prodRequired")) {
    return { customsValueDisabled: true, deliveryDescriptionHidden: true };
  }

  return {
    customsValue: true,
    deliveryDescription,
    deliveryDescriptionHidden: newDeliveryDescriptionHidden,
  };
};

// TODO: test new functionality on old logic, if ok - remove it
// @see:https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_wg82hl6GAqAADN2l.html
const getNewVersionCustomsFieldsConfig = (
  service,
  country,
  generateCustomsData
) => {
  if (isEmpty(service)) return {};

  const deliveryDescription = ServiceModels.isFlagRequired(
    service,
    "contentsRequired"
  );

  if (
    ServiceModels.isFlagRequired(service, "proforma") ||
    ServiceModels.isFlagOptional(service, "proforma")
  ) {
    if (ServiceModels.isFlagRequired(service, "prodRequired")) {
      return {
        deliveryDescription,
        customsValueDisabled: true,
      };
    }

    if (
      !generateCustomsData &&
      ServiceModels.isFlagNeedles(service, "prodRequired")
    ) {
      return {
        deliveryDescription,
        customsValue: true,
      };
    }

    return { customsValue: true, deliveryDescription };
  }

  if (
    ServiceModels.isFlagNeedles(service, "proforma") &&
    AddressModels.isCountryGB(country.countryKey)
  ) {
    if (
      ServiceModels.isFlagRequired(service, "prodRequired") ||
      ServiceModels.isFlagOptional(service, "prodRequired")
    ) {
      return { customsValueDisabled: true, deliveryDescriptionHidden: true };
    }

    return {};
  }

  if (ServiceModels.isFlagRequired(service, "prodRequired")) {
    return { customsValueDisabled: true, deliveryDescriptionHidden: true };
  }

  return { customsValue: true, deliveryDescription };
};

export const getShipmentCustomsFieldsConfig = (
  service,
  country,
  generateCustomsData,
  customer
) =>
  // TODO: test new functionality on old logic, if ok - remove it
  // SettingsModels.isNewVersion(customer.shippingVersion)
  //   ? getNewVersionCustomsFieldsConfig(service, country, generateCustomsData)
  //   : getOldVersionCustomsFieldsConfig(
  //       service,
  //       country,
  //       generateCustomsData,
  //       customer
  //     )
  getOldVersionCustomsFieldsConfig(
    service,
    country,
    generateCustomsData,
    customer
  );

export const isCustomsVisible = (
  service,
  country,
  generateCustomsData,
  customer
) =>
  !isEmpty(
    getShipmentCustomsFieldsConfig(
      service,
      country,
      generateCustomsData,
      customer
    )
  );

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_aBI0iN6GAqAAhaeB.html
export const isCustomsFieldsVisible = (
  service,
  country,
  formValues,
  customer
) => {
  const generateCustomsData = get(
    formValues,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  return isCustomsVisible(service, country, generateCustomsData, customer);
};

// @see https://it.dpduk.live/it/diagram/diag_1jl2ul6GAqAADNn6.html
export const getCustomsFieldsConfig = (
  service,
  country,
  formValues,
  customer
) => {
  const generateCustomsData = get(
    formValues,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  return {
    ...getShipmentCustomsFieldsConfig(
      service,
      country,
      generateCustomsData,
      customer
    ),
    [Fields.EXPORT_REASON]:
      generateCustomsData &&
      ServiceModels.isFlagRequired(service, "exportReasonRequired"),
    [Fields.SHIPPING_COST]:
      generateCustomsData &&
      ServiceModels.isFlagRequired(service, "costsRequired"),
  };
};

export const setupInitialCustomsFields = data => {
  const { shipment, selectedOutboundNetwork, customer } = data;
  const country = {
    countryKey: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ),
  };
  const visibleFields = isCustomsFieldsVisible(
    selectedOutboundNetwork,
    country,
    shipment,
    customer
  );
  const outboundConsignment = shipment.outboundConsignment;
  const generateCustomsData = getValue(
    shipment,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );
  const { customsValueDisabled } = getShipmentCustomsFieldsConfig(
    selectedOutboundNetwork,
    country,
    generateCustomsData,
    customer
  );
  const shipmentCustomsValue = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE
  );

  if (!shipmentCustomsValue && customsValueDisabled) {
    const calculatedCustomsValue = parseFloat(
      ParcelModels.calculateParcelsTotalValues(
        get(shipment, "outboundConsignment.parcels", [])
      )
    ).toFixed(2);
    set(outboundConsignment, Fields.CUSTOMS_VALUE, calculatedCustomsValue);
  } else {
    set(
      outboundConsignment,
      Fields.CUSTOMS_VALUE,
      roundToDecimal(shipmentCustomsValue)
    );
  }

  if (!visibleFields) {
    delete outboundConsignment.customsValue;
    delete outboundConsignment.currency;
    delete outboundConsignment.deliveryDescription;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const isCustomsValue = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE;
