import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { propTypes } from "redux-form";
import { useMemo } from "react";

import { Step, Card } from "@dpdgroupuk/mydpd-ui";
import ReviewCard from "~/components/ReviewCard";

import DeliveryDetailsContent from "~/components/DeliveryDetailsContent";
import DeliveryContactContent from "~/components//DeliveryContactContent";
import PackageDetailsContent from "~/components/PackageDetailsContent";
import PackageServiceDetailsContent from "~/components/PackageServiceDetailsContent";
import ProductDescriptionsContent from "~/components/ProductDescriptionsContent";
import {
  DELIVERY_DETAILS,
  DELIVERY_CONTACT,
  PARCEL_DETAILS,
  PACKAGE_SERVICE_DETAILS,
  ProductDescriptionsEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const ShipmentDetails = props => {
  const { createShipmentSyncErrors, createShipmentSubmitErrors } = props;

  const isValidDeliveryDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidShipmentDetailsSection({
        errors: createShipmentSyncErrors,
        submitErrors: createShipmentSubmitErrors,
      }),
    [createShipmentSyncErrors, createShipmentSubmitErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidDeliveryDetailsSection}
      title={S.SHIPMENT_DETAILS_TITLE}
    >
      <Row>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.ADDRESS_DETAILS}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[DELIVERY_DETAILS]}
                helpModalTitle={S.ADDRESS_DETAILS}
              />
            }
          >
            <DeliveryDetailsContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.CONTACT_DETAILS}
            classes={{ card: "h-100" }}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[DELIVERY_CONTACT]}
                helpModalTitle={S.CONTACT_DETAILS}
              />
            }
          >
            <DeliveryContactContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <ReviewCard
            title={S.PARCEL_DETAILS}
            classes={{ card: "h-100" }}
            rounded
            isTitleWithGap
            action={
              <Card.HelpIcon
                help={help[PARCEL_DETAILS]}
                helpModalTitle={S.PARCEL_DETAILS}
              />
            }
          >
            <PackageDetailsContent {...props} />
          </ReviewCard>
        </Col>
        <Col md={6} className="mb-3">
          <Row>
            <Col xs={12} className="mb-3">
              <ReviewCard
                title={S.PACKAGE_SERVICE_DETAILS}
                rounded
                isTitleWithGap
                action={
                  <Card.HelpIcon
                    help={help[PACKAGE_SERVICE_DETAILS]}
                    helpModalTitle={S.PACKAGE_SERVICE_DETAILS}
                  />
                }
              >
                <PackageServiceDetailsContent {...props} />
              </ReviewCard>
            </Col>
            {props.allowedFields[
              [ProductDescriptionsEntity.PRODUCT_DESCRIPTION]
            ] && (
              <Col xs={12}>
                <ReviewCard
                  title={S.PRODUCT_DESCRIPTIONS}
                  rounded
                  isTitleWithGap
                >
                  <ProductDescriptionsContent
                    pageConfig={props.pageConfig}
                    onFieldEntry={props.onFieldEntry}
                  />
                </ReviewCard>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Step>
  );
};

ShipmentDetails.propTypes = {
  ...propTypes,
  allowedFields: PropTypes.object,
};

export default ShipmentDetails;
